<template>
  <div class="lsg-tasks">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right">
        <button class="btn" type="button" @click="$router.push({ name: 'add_memo' })">
          <PlusIco/>
          <span>Служебка</span>
        </button>
      </div>
    </div>
    <div v-if="$store.state.me.role !== 'partner'" class="main__header main__header--secondary">
      <div class="toper__item"></div>
      <div class="toper__item">
        <!-- <div class="button-group">
          <button type="button" :class="{ active: $store.state.data.memos.sort2 === 'today' }" @click="setSortType('today')">
            <span>Сегодня</span>
          </button>
          <button type="button" :class="{ active: $store.state.data.memos.sort2 === 'tomorrow' }" @click="setSortType('tomorrow')">
            <span>Вчера</span>
          </button>
          <button type="button" :class="{ active: $store.state.data.memos.sort2 === 'week' }" @click="setSortType('week')">
            <span>Неделя</span>
          </button>
          <button type="button" :class="{ active: $store.state.data.memos.sort2 === 'month' }" @click="setSortType('month')">
            <span>Месяц</span>
          </button>
          <button type="button" @click="openPeriod = true" :class="{ active: $store.state.data.memos.sort2 === 'period' && $store.state.data.memos.sortDate }" ref="buttonPeriod" v-click-outside="vcoConfig">
            <i>
              <CalendarIcon/>
            </i>
            <span>Период</span>
            <b class="date-picker" v-show="openPeriod">
              <date-picker v-model="$store.state.data.memos.sortDate" ref="pickerPeriod" inline range/>
            </b>
          </button>
        </div> -->
      </div>
      <div class="toper__item right"></div>
      <!-- <div class="toper__item"></div> -->
    </div>
    <div class="main__content">
      <MemosTable v-if="isTableView"/>
      <!-- <MemosKanban v-if="isKanbanView" :callCenter="callCenter" :internet="internet"/> -->
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import MemosTable from './components/MemosTable'
import PlusIco from '@/components/svg/plus'
// import CalendarIcon from '@/components/svg/CalendarIcon'
// import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'

export default {
  name: 'Memos',
  data () {
    return {
      title: 'Служебки',
      links: [
        {
          name: 'Главная',
          route: 'home'
        },
        {
          name: 'Служебки'
        }
      ],
      view: 'table',
      page: 0,
      perPage: 50,
      sort: {
        field: 'date',
        order: 'desc'
      },
      // eslint-disable-next-line vue/no-reserved-keys
      filter: {
        q: '',
        status: null,
        tags: []
      },
      openPeriod: false,
      vcoConfig: {
        handler: () => {
          this.openPeriod = false
        }
      }
    }
  },
  components: {
    // CalendarIcon,
    MemosTable,
    PlusIco
    // DatePicker
  },
  created () {
    this.page = this.$store.state.data.memos.page
    this.perPage = this.$store.state.data.memos.perPage
    this.sort = this.$store.state.data.memos.sort
    this.filter = this.$store.state.data.memos.filter

    if (window.location.hash === '') {
      this.$store.state.data.memos.view = 'table'
      window.location.hash = this.$store.state.data.memos.view
    } else {
      this.$store.state.data.memos.view = window.location.hash.substr(1)
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.state.data.memos.page = this.page
    this.$store.state.data.memos.perPage = this.perPage
    this.$store.state.data.memos.sort = this.sort
    this.$store.state.data.memos.filter = this.filter
    next()
  },
  computed: {
    pageCount () {
      return Math.ceil(this._allMemosMeta / this.perPage)
    },
    getContractFilter () {
      let currentFilter = {}
      if (this.filter.q) currentFilter.q = this.filter.q
      if (this.filter.status) currentFilter.status = this.filter.status
      if (this.filter.tags.length) currentFilter.tags = this.filter.tags

      return currentFilter
    },
    isTableView () {
      return this.$store.state.data.memos.view === 'table'
    },
    isKanbanView () {
      return this.$store.state.data.memos.view === 'kanban'
    }
  },
  watch: {
    '$store.state.data.memos.view' () {
      window.location.hash = this.$store.state.data.memos.view
    },
    '$store.state.data.memos.sortDate' () {
      if (this.$store.state.data.memos.sortDate) {
        this.$store.state.data.memos.sort2 = 'period'
      } else {
        this.$store.state.data.memos.sort2 = null
      }
      this.openPeriod = false
    }
  },
  methods: {
    setSortType (type) {
      if (this.$store.state.data.memos.sort2 === type) {
        this.$store.state.data.memos.sort2 = null
      } else {
        this.$store.state.data.memos.sort2 = type
      }
    },
    setPage (page) {
      this.page = page - 1
    },
    goToPrevPage (e) {
      e.preventDefault()
      if (this.page !== 0) {
        this.page--
      }
    },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    isDisabled (dataLength) {
      return (dataLength < this.perPage) || (this._allMemosMeta === (this.page + 1) * this.perPage)
    },
    sortingField (columnName) {
      if (columnName === this.sort.field) this.sort.order = this.sort.order === 'asc' ? 'desc' : 'asc'
      this.sort.field = columnName
    },
    getSortableClass (columnName) {
      let currentClass = 'lsg-table-sort'
      if (columnName === this.sort.field) currentClass += ' sort-' + this.sort.order

      return currentClass
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString()
    },
    clearFilters () {
      this.$store.state.data.memos.filter.q = ''
      this.$store.state.data.memos.filter.st = null
      this.$store.state.data.memos.filter.status = null
      this.$store.state.data.memos.filter.status_date = null
      this.$store.state.data.memos.filter.tags = []
      this.$store.state.data.memos.filter.partner.id = null
      this.$store.state.data.memos.filter.partner.name = null
      this.$store.state.data.memos.filter.partner.inn = null
      this.$store.state.data.memos.filter.signed = null
      this.$store.state.data.memos.filter.unloaded = null
      this.$store.state.data.memos.filter.advance = null
    }
  }
}
</script>
