<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <ApolloQuery :query="require('@/graphql/queries/allMemos.gql')"
    :variables="{
      page,
      perPage,
      filter: getMemosFilter
    }"
    @result="doneLoadingMemos">
    <template slot-scope="{ result: { error, data }, isLoading }">
      <div class="modal" style="align-items: flex-start" v-if="showModal">
        <div class="modal__back" @click="showModal = false"></div>
        <div class="modal__body modal__body--task-do">
          <div class="modal__attention" style="white-space: pre-wrap; overflow-y: auto">
            <span class="modal__attention__title">{{ selectedRow.represent }}</span>
            <!-- <span v-if="selectedRow.comment" class="modal__attention__title" style="color:darkgrey;">{{selectedRow.comment}}</span> -->
            <span class="modal__attention__title" style="color:darkgrey;">{{content}}</span>
            <!-- <div v-if="selectedRow.text !== '' || selectedRow.history !== ''" @click="() => subtitleExpanded = !subtitleExpanded"><a style="cursor: pointer">{{ subtitleExpanded? 'Свернуть': 'Развернуть'}}</a></div> -->
            <div class="modal__attention__container" style="overflow: auto">
              <div v-if="history.length > 0" class="modal__attention__table">
                <vue-good-table
                  :columns="columnsAgreement"
                  :rows="history"
                  compactMode
                >
                  <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'autor'">{{ props.row.autor }}</div>
                    <div v-if="props.column.field === 'result' && props.row.result === 'Согласовано'" class="tag tag--green">{{ props.row.result }}</div>
                    <div v-else-if="props.column.field === 'result' && props.row.result === 'СогласованоСЗамечаниями'" class="tag tag--yellow">{{ props.row.result }}</div>
                    <div v-else-if="props.column.field === 'result' && props.row.result === 'НеСогласовано'" class="tag tag--red">{{ props.row.result }}</div>
                    <div v-if="props.column.field === 'comment'">{{ props.row.comment }}</div>
                  </template>
                </vue-good-table>
              </div>
              <div v-if="selectedRow.Content" @click="openHistory = !openHistory" class="trade__modal__header" style="cursor: pointer">
                <span class="modal__attention__title">История</span>
                <div class="history__icon">
                  <svg v-if="!openHistory" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 5V19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M5 12H19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                  <MinusIco v-else/>
                </div>
              </div>
              <span v-if="selectedRow.Content" class="modal__attention__subtitle modal__attention__subtitle__history" :class="{'modal__attention__subtitle__history--active': openHistory}"  style="color:darkgrey;">{{selectedRow.Content}}</span>
            </div>
            <div v-if="docs.length > 0" style="width: 100%">
              <span class="modal__attention__title">Документы</span>
              <ul>
                <li v-for="(item, index) in docs" :key="index"><a class="modal__attention__files" :href="`${BASE_URL}/file?id=${item.id}`" download>{{`${item.name}.${item.ext}`}}</a></li>
              </ul>
            </div>
            <div v-if="selectedRow.status==='Согласовать' || selectedRow.status==='Исполнить' || 'Проверить исполнение'" style="margin:5px;width:70%;">
              <label style="color:grey;">Комментарий:</label>
              <input type="text" v-model="message" style="margin:3px;" >
            </div>
            <div class="modal__attention__btns" style="justify-content:center;">
              <button v-if="selectedRow.status==='Исполнить'" class="modal__attention__btn modal__attention__btn--orange" @click="makeDone">Исполнено</button>
              <!-- <button v-if="selectedRow.status==='Утвердить'" class="modal__attention__btn modal__attention__btn--green" @click="makeSign">Подписать</button>
              <button v-if="selectedRow.status==='Утвердить'" class="modal__attention__btn modal__attention__btn--orange" @click="makeReject">Отклонить</button> -->
              <button v-if="selectedRow.status==='Согласовать'" :disabled='noComment' class="modal__attention__btn modal__attention__btn--green" @click="makeApprove">Согласовано</button>
              <button v-if="selectedRow.status==='Согласовать'" :disabled='noComment' class="modal__attention__btn modal__attention__btn--orange" @click="makeReject">Не согласовано</button>
              <button v-if="selectedRow.status==='Проверить исполнение'" :disabled='noComment' class="modal__attention__btn modal__attention__btn--green" @click="makeDone">Проверено</button>
              <button v-if="selectedRow.status==='Проверить исполнение'" :disabled='noComment' class="modal__attention__btn modal__attention__btn--orange" @click="makeReturnForRevision">Вернуть на доработку</button>
              <button v-if="selectedRow.status.includes('Ознакомиться')" class="modal__attention__btn modal__attention__btn--green" @click="makeDone">Завершить</button>
              <button v-if="selectedRow.status.includes('результат')  && selectedRow.result !== 'Согласовано'" class="modal__attention__btn modal__attention__btn--orange" @click="makeReturn">Повторить согласование</button>
              <button class="modal__attention__btn" @click="showModal = false">Закрыть</button>
            </div>
          </div>
        </div>
      </div>
      <div class="tbl tbl--divided">
        <div class="tbl__header">
          <div class="tbl__filter">
            <label class="tbl__filter__item">
              <v-select appendToBody
                        :options="[
                          'Согласованные',
                          'Несогласованные',
                          'Все'
                        ]"
                        placeholder="Статус служебки"
                        @input="onMemoStatusChange"
                        :value="filter.status ? filter.status : 'Все'"
              >
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <label class="tbl__filter__item">
              <date-picker :formatter="momentFormat" range v-model="filter.period">
                <template v-slot:footer="{ emit }">
                  <div>
                    <button @click="selectThisMonth(emit)" class="mx-btn mx-btn-text">Этот месяц</button>
                    <button @click="selectThisYear(emit)" class="mx-btn mx-btn-text">Этот год</button>
                    <button @click="selectLastYear(emit)" class="mx-btn mx-btn-text">Предыдущий год</button>
                  </div>
                </template>
              </date-picker>
            </label>
          </div>
        </div>
        <div class="messages apollo" v-if="isLoading">
          <div class="messages__status">Загрузка...</div>
        </div>
        <div class="messages apollo" v-else-if="error">
          <div class="messages__status error">Ошибка загрузки данных. Обратитесь в тех. поддержку</div>
        </div>
        <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
          <vue-good-table
            :columns="columns"
            :rows="data ? data.allMemos : []"
            compactMode
            @on-row-click="onRowClick"
            @on-sort-change="sortingField2"
            :pagination-options="{enabled: true, perPage: perPage}"
          >
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field === 'theme'">{{ props.row.theme }}</div>
              <div v-else-if="props.column.field === 'lead' && props.row.memo.type === 'Лизинговая сделка'">
                <router-link :to="{ name: 'trade', params: { id: props.row.memo.link } }">{{ props.row.name_Deal }}</router-link>
              </div>
              <div v-else-if="props.column.field === 'contract' && props.row.memo.type === 'Договор лизинга'">
                <router-link :to="{ name: 'contract', params: { id: props.row.memo.link } }">{{ props.row.name_contract }}</router-link>
              </div>
              <div v-else-if="props.column.field === 'date'">{{ getDate(props.row.date) }}</div>
            </template>
            <div slot="emptystate">Данных нет</div>
            <template slot="pagination-bottom" slot-scope="props">
              <div class="vgt-wrap__footer vgt-clearfix">
                <div class="footer__row-count vgt-pull-left">
                  <span class="footer__row-count__label">Показано</span>
                  <select @change="perPageChanged(props)" v-model="perPage" class="footer__row-count__select">
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                    <option :value="200">200</option>
                  </select>
                </div>
              </div>
              <paginate
                :click-handler="setPage"
                :page-count="pageCount"
                :value="page + 1"
                container-class="tbl__pagination vgt-pull-right"
                prev-text="&lsaquo;"
                next-text="&rsaquo;">
                <span slot="prevContent">
                  <ArrowLeftIcon/>
                </span>
                <span slot="nextContent">
                  <ArrowRightIcon/>
                </span>
              </paginate>
            </template>
          </vue-good-table>
        </mq-layout>
        <mq-layout :mq="[ 'xsm', 'md' ]" class="tbl__container">
          <table>
            <thead>
            <tr>
              <th>
                <div class="tbl__cols">
                  <span>Статус</span>
                  <span>Дата</span>
                </div>
              </th>
              <th>
                <div class="tbl__cols">
                  <span>Тема</span>
                  <span>Номер</span>
                </div>
              </th>
              <th>
                <div class="tbl__cols">
                  <span>Лизинговая сделка</span>
                  <span>Договор лизинга</span>
                </div>
              </th>
            </tr>
            </thead>
            <tbody v-if="isLoading">
            <tr>
              <td colspan="3">Загрузка...</td>
            </tr>
            </tbody>
            <tbody v-else-if="data">
            <tr :key="index" v-for="(memo, index) in data.allMemos">
              <td>
                <div class="tbl__content__container">
                  <div class="tbl__content">
                    <span>{{ memo.status }}</span>
                    <span>{{ getDate(memo.date) }}</span>
                  </div>
                </div>
              </td>
              <td>
                <div class="tbl__content">
                  <span>{{ memo.theme }}</span>
                  <span>{{ memo.Number }}</span>
                </div>
              </td>
              <td>
                <div class="tbl__content">
                  <span>{{ memo.lead }}</span>
                  <span>{{ memo.contract }}</span>
                </div>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="5">Нет результатов</td>
            </tr>
            </tbody>
          </table>
        </mq-layout>
        <mq-layout :mq="[ 'xs', 'sm' ]" class="tbl__container tbl__mobile">
          <table>
            <tbody v-if="isLoading">
            <tr>
              <td colspan="1">Загрузка...</td>
            </tr>
            </tbody>
            <tbody v-else-if="data">
            <tr :key="index" v-for="(memo, index) in data.allMemos">
              <td>
                <div class="tbl__content__container">
                  <div class="tbl__content">
                    <span>{{ memo.status }}</span>
                    <span>{{ getDate(memo.date) }}</span>
                    <span>{{ memo.theme }}</span>
                    <span>{{ memo.Number }}</span>
                    <span>{{ memo.lead }}</span>
                    <span>{{ memo.contract }}</span>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="1">Нет результатов</td>
            </tr>
            </tbody>
          </table>
        </mq-layout>
      </div>
      <mq-layout :mq="[ 'xs', 'sm', 'xsm', 'md' ]" class="tbl__footer">
        <div class="tbl__footer__item left"></div>
        <div class="tbl__footer__item">
          <paginate :page-count="pageCount" :value="page + 1" :click-handler="setPage" prev-text="&lsaquo;" next-text="&rsaquo;" container-class="tbl__pagination vgt-pull-right">
            <span slot="prevContent">
              <ArrowLeftIcon/>
            </span>
            <span slot="nextContent">
              <ArrowRightIcon/>
            </span>
          </paginate>
        </div>
        <div class="tbl__footer__item right">
          <label class="tbl__pagination__info">
            Показано
            <span class="bold">{{ perPage }}</span>
            из
            <span class="bold">{{ pageCount }}</span>
          </label>
        </div>
      </mq-layout>
    </template>
  </ApolloQuery>
</template>

<script>
import moment from 'moment'
import { extendMoment } from 'moment-range'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import ArrowLeftIcon from '@/components/svg/arrow_left'
import ArrowRightIcon from '@/components/svg/arrow_right'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import axios from 'axios'
import VISAS from '@/graphql/queries/DocVisas.gql'
import MinusIco from '@/components/svg/minus'
import DownIcon from '@/components/svg/DownIcon'

const _moment = extendMoment(moment)
const Today = _moment()

const BASE_URL = process.env.VUE_APP_HTTP
const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'MemosTable',
  data: () => {
    return {
      title: 'Служебки',
      BASE_URL,
      message: '',
      showModal: false,
      selectedMemos: [],
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('DD.MM.YYYY') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'DD.MM.YYYY').toDate() : null
        }
      },
      allMemosMeta: 0,
      page: 0,
      perPage: 10,
      sort: {
        field: 'date',
        order: 'desc'
      },
      filter: {
        status: 'Все',
        period: [
          new Date(2019, 0, 1),
          new Date(Date.now())
        ],
        sortDate: null
      },
      columns: [
        { label: 'Дата', field: 'date', hidden: false },
        { label: 'Номер', field: 'Number', hidden: false, sortable: false },
        { label: 'Ответственный', field: 'Responsible', hidden: false, sortable: false },
        { label: 'Тема', field: 'theme', hidden: false, sortable: false },
        { label: 'Статус', field: 'status', hidden: false, sortable: false },
        { label: 'Лизинговая сделка', field: 'lead', hidden: false, sortable: false },
        { label: 'Договор лизинга', field: 'contract', hidden: false, sortable: false },
        { label: 'Комментарий', field: 'Comment', hidden: false, sortable: false }
      ],
      columnsAgreement: [
        {
          label: 'Согласующее лицо',
          field: 'autor'
        },
        {
          label: 'Результат',
          field: 'result'
        },
        {
          label: 'Комментарий',
          field: 'comment'
        }
      ],
      createdAt: null,
      periodStart: _moment(Today).startOf('month').format('YYYY-MM-DD'),
      periodEnd: _moment(Today).endOf('month').add(1, 'days').format('YYYY-MM-DD'),
      countMemos: 0,
      isLoading: true,
      openPeriod: false,
      filterStatus: 'Все',
      sortDate: -1,
      openHistory: false,
      datePeriod: null,
      daysPeriod: 'month',
      resultPeriod: null,
      history: []
    }
  },
  components: {
    ArrowRightIcon,
    MinusIco,
    DownIcon,
    ArrowLeftIcon,
    DatePicker
  },
  computed: {
    pageCount () {
      return Math.ceil(this.allMemosMeta / this.perPage)
    },
    getMemosFilter () {
      let currentFilter = {}
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (this.filter.status) currentFilter.status = this.filter.status
      if (this.filter.period) currentFilter.period = this.filter.period
      if (this.filter.sortDate) currentFilter.sortDate = this.filter.sortDate
      return currentFilter
    }
  },
  methods: {
    sortingField2 (params) {
      this.filter.sortDate = params[0].type
    },
    transformColumns (value) {
      if (value) {
        const result = value.filter(item => item.field !== 'manager')
        return result
      }
    },
    onStatusChange (e) {
      if (e) {
        this.filter.status = e.value
      } else {
        this.filter.status = null
      }
      this.setPage(1)
    },
    switchColumnModal () {
      this.$store.state.data.memos.columnModal = !this.$store.state.data.memos.columnModal
    },
    perPageChanged (props) {
      props.perPageChanged({ currentPerPage: this.perPage })
    },
    selectThisMonth (emit) {
      let date = new Date()
      let y = date.getFullYear()
      let m = date.getMonth()
      var firstDay = new Date(y, m, 1)
      var lastDay = new Date(y, m + 1, 0)
      emit([firstDay, lastDay])
    },
    selectThisYear (emit) {
      let date = new Date()
      let y = date.getFullYear()
      var firstDay = new Date(y, 0, 1)
      var lastDay = new Date(y, 11, 31)
      emit([firstDay, lastDay])
    },
    selectLastYear (emit) {
      let date = new Date()
      let y = date.getFullYear() - 1
      var firstDay = new Date(y, 0, 1)
      var lastDay = new Date(y, 11, 31)
      emit([firstDay, lastDay])
    },
    onMemoStatusChange (status) {
      if (status) {
        this.filter.status = status
      } else {
        this.filter.status = 'Все'
      }
      this.setPage(1)
    },
    doneLoadingMemos ({ data, loading }) {
      if (data) {
        this.$nextTick(() => {
          this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
        })
        this.allMemosMeta = data._allMemosMeta ? data._allMemosMeta.count : 0
      }
    },
    async getDocs (id) {
      const docs = await axios.get(`${BASE_URL}/doc?uid=${id}`)
      return docs.data
    },
    setPage (page) {
      this.page = page - 1
    },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    async getContext (id) {
      const content = await axios.get(`${BASE_URL}/doc/data?id=${id}`)
      return content.data.content
    },
    async downloadFile (id) {
      await axios.get(`${BASE_URL}/file?id=${id}`)
    },
    async getVisas (doc) {
      let history = []
      await this.$apollo
        .mutate({
          mutation: VISAS,
          variables: {
            doc
          },
          update: (_, { data }) => {
            if (data && data.docVisas.length > 0) {
              history = [...data.docVisas]
            }
          }
        })
        .catch((error) => {
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      return history
    },
    async onRowClick (params) {
      this.selectedRow = params.row
      this.docs = await this.getDocs(params.row.id)
      this.content = await this.getContext(params.row.id)
      this.history = await this.getVisas(params.row.id)
      this.showModal = true
    }
  }
}
</script>

<style lang="stylus">

.btn--disable {
  background $c979797 !important
  color $white !important
  border-color $c979797 !important

  &:hover {
    background $c979797 !important
    cursor auto
  }

  &:active {
    transform none !important
  }
}

</style>
